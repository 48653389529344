import React from "react";
import "./style.css";
import Social from "../../components/Social-icons";
import { Link } from "react-scroll";

export default function Footer() {
  return (
    <footer className="fade" id="footer">
        <Social />
        <nav className="footer-nav">
          <Link to="/terms">Terms of Use</Link>
          <p><span>Get in Touch:</span> <a href="mailto:brittney@eskewconsulting.com">brittney@eskewconsulting.com</a></p>
        </nav>
        <p className="copy">Copyright &copy;{(new Date().getFullYear())} </p>
    </footer>
  );
}