import React, { useState } from "react";
import "./style.css";
import Content from "../../content/home";
import SingleQuestion from "../SingleQuestion";

export default function Questions(){
    const [content] = useState({
        ...Content
    });

    let { title, questions } = content[0].questions[0];
    let array = [...questions];
    let questionList = array.map((e, i) =>
        <SingleQuestion  question={e.question} answer={e.answer} key={i}/>
    );
    return (
        <section id="questions">
            <h2>{title}</h2>
            <ul>
                {questionList}
            </ul>
        </section>
    )
}