import React from "react";
import { useState } from "react";
import Booking from "../../pages/Booking"

export default function Calendar() {
    const [ openState, setOpenState ] = useState({
        isOpen: false
    });
    function showBooking() {
        setOpenState( state => ({
            isOpen: !state.isOpen
        }))
    };

    return (
        <section id="calendar">
            <div class="text-block">
                <h2>Book a Session</h2>
                <p>If you would like to schedule an In-Studio session with Brittney Eskew, please email <a href="mailto:info@eskewstudios.com">info@eskewstudios.com</a>.</p>
                <button onClick={()=> showBooking()} className="cta transition">Book a session</button>
            </div>
            <Booking className={openState.isOpen ? "book-now" : ""}/>
            
        </section>
    )
}