import React, {useState} from "react"

export default function StaffMember(props) {
    const [ openState, setOpenState ] = useState({
        isOpen: false
    });
    function showBio() {
        setOpenState( state => ({
            isOpen: !state.isOpen
        }))
    };
    function hideBio() {
        setOpenState( state => ({
            isOpen: !state.isOpen
        }))
    };
    return (
        <div className={openState.isOpen ? "staffMember clearfix expand" : "staffMember clearfix"}>
            <img src={props.img} alt={`${props.name}'s head shot`} className="staffImg transition" loading="lazy"/>
            <div className="staffBio transition"> 
                <button className="staffClose transition" onClick={()=> showBio()}>+</button>
                <div onClick={()=> showBio()}>
                    <h3>{props.name}</h3>
                    <h4>{props.role}</h4>
                    {props.specialty ? <h5>{props.specialty}</h5> : ""}
                    {/* <button className="client-button transition" onClick={()=> showBio()}>See {props.name}'s bio</button> */}
                </div>
                <p>{props.bio}</p>
            </div>
        </div>
    )
}