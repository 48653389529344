import React from "react";
import content from "../../content/home.json";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import StaffMember from "../../components/StaffMember";
import "./style.css";

export default function Staff() {
    const { title, staff } = content[0].staff[0];
    let staffMembers = staff.map(s => 
        <StaffMember
            img={s.img}
            name={s.name}
            role={s.role}
            specialty={s.specialty}
            bio={s.bio}
        />
        
    )
    return(
        <section id="staff">
            <h2>{title}</h2>
            {staffMembers}
        </section>
    )
}
