import React, { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import {gsap, Elastic} from "gsap";
import "./style.css";
import Logo from "../../components/SVGS/logo"
import Social from "../../components/Social-icons";

export default function Header() {
    const tl = gsap.timeline();
    let targetWidth = 1000;
    let w = document.documentElement.clientWidth ||
        document.body.clientWidth ||
        window.innerWidth;
    const [ fixedState, setFixedState ] = useState({
        isFixed: false
    });
    function setFixed() {
        setFixedState( state => ({
            isFixed: !state.isFixed
        }))
    };
    useEffect( ()=> {
        window.addEventListener('scroll', ()=> {
            const isTop = window.scrollY < 400;
            if (isTop === fixedState.isFixed) {
                setFixedState(state=> ({
                    isFixed: true
                }))
            } else {
                setFixedState(state=> ({
                    isFixed:false
                }))
            }
        });
        if(w >= targetWidth) {
            tl.from('header', {
                delay:4,
                duration:1,
                ease: Elastic.easeInOut,
                y:-150
            })
        } else {
            console.log('header too small')
        }
    }, []);
    console.log(window.location);
    return (
        <header className={fixedState.isFixed ? "fixed transition" : "transition"}>
            {/* museo sans TOP imperfecta BOTTOM*/}
            {/* <Link to="/"><img class="logo" src="img/eskew-consulting-logo.svg" alt="Eskew Consulting logo"/></Link> */}
            <Link to="/">
                <Logo className="logo"/>
            </Link>
            <nav>
                <Link
                    activeClass="active" 
                    to="calendar"
                    spy={true}
                    smooth={true}
                    offset={-103}
                    duration={500}
                    className="transition"
                >Book a session</Link>
                <Link
                    activeClass="active" 
                    to="footer"
                    spy={true}
                    smooth={true}
                    offset={-103}
                    duration={500}
                    className="transition"
                >Get in touch</Link>
                <Link
                    activeClass="active" 
                    to="name-container"
                    spy={true}
                    smooth={true}
                    offset={-103}
                    duration={500}
                    className="transition"
                >Meet the team</Link>
            </nav>
            <Social />
        </header>
    )
}