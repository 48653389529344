import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Calendar from "./calendar";
import Intro from "./intro";
import Services from "./services";
import About from "./about";
import Staff from "./staff";
import Clients from "./clients";
import Questions from "../../components/Questions";

// import Grid from "../../components/Grid";
// import Colors from "../../components/Colors";
// import Art1 from "../../components/SVGS/art1";
// import Art2 from "../../components/SVGS/art2";
// import Floral from "../../components/SVGS/floral";
// import Triangles from "../../components/SVGS/triangles";

import "./style.css";

export default function Home() {
    // gsap.registerPlugin(ScrollTrigger);
    // const tl = gsap.timeline({ defaults: {
    //     duration: 1,
    //     ease: 'none'
    //  }});
    //  let targetWidth = 1000;
    //  let w = document.documentElement.clientWidth ||
    //     document.body.clientWidth ||
    //     window.innerWidth;

    // const colors = [
    //     '#c3dcd9', // light blue
    //     '#bfc014', // green
    //     '#999e9f', // slate
    //     '#ffffff', // white
    //     '#c3dcd9' // light blue
    // ];
    // const colors = [
    //     '#ffffff',
    //     '#c3dcd9',
    //     '#999e9f',
    //     '#c3dcd9',
    //     '#ffffff'
    // ]
    // const colors = [
    //     '#ffffff',
    //     '#ffffff',
    //     '#ffffff',
    //     '#ffffff',
    //     '#ffffff'
    // ]

    // useEffect(() => {
    //     if( w >= targetWidth) {
    //     tl.fromTo( 'body', {
    //         backgroundColor: colors[0]
    //     }, {
    //         backgroundColor: colors[1],
    //         scrollTrigger: {
    //             id: "background1",
    //             trigger: '#about',
    //             start: 'top bottom',
    //             end: 'bottom bottom',
    //             scrub: true,
    //             toggleActions: 'play none none reverse'
    //         }
    //     }).fromTo( 'body', {
    //         backgroundColor: colors[1],
    //     }, {
    //         backgroundColor: colors[2],
    //         scrollTrigger: {
    //             id: "background2",
    //             trigger: '#services',
    //             start: 'top bottom',
    //             end: 'bottom bottom',
    //             scrub: true,
    //             toggleActions: 'play none none reverse'
    //         }
    //     }).fromTo( 'body', {
    //         backgroundColor: colors[2],
    //     }, {
    //         backgroundColor: colors[3],
    //         scrollTrigger: {
    //             id: "background3",
    //             trigger: '#clients',
    //             start: 'top bottom',
    //             end: 'bottom bottom',
    //             scrub: true,
    //             toggleActions: 'play none none reverse'
    //         }
    //     }).fromTo( 'body', {
    //         backgroundColor: colors[3],
    //     }, {
    //         backgroundColor: colors[4],
    //         scrollTrigger: {
    //             id: "background4",
    //             trigger: '#calendar',
    //             start: 'top bottom',
    //             end: 'bottom bottom',
    //             scrub: true,
    //             toggleActions: 'play none none reverse'
    //         }
    //     })
    // } else {console.log('less than')}
    // }, []);
    return (
        <div className="page">
            
            <Intro />
            {/* <video controls class="video-container" poster="img/video-poster.jpg">
                <source src="img/selfTrustIsAMust.mp4" type="video/mp4" />
            </video> */}
            <Services />
            <Clients />
            <About />
            <Staff />
            <Calendar />
            <Questions />
            
            {/* <Grid />
            <Colors />
            <Art1 />
            <Triangles />
            <Art2 />
            <Floral /> */}
        </div>
    )
}