import React, {useState} from "react";

export default function Client(props) {
    const [ openState, setOpenState ] = useState({
        isOpen: false
    });
    function showQuote() {
        setOpenState( state => ({
            isOpen: !state.isOpen
        }))
    };
    return (
        <div className={openState.isOpen ? "expand client-container clearfix" : "client-container clearfix"} id={props.id}>
            <img src={props.img} loading="lazy" alt={props.name + " - " + props.competition} className="client-image" id={`${props.id}-img`} />
            <div className="client" id={`${props.id}-div`}>
                <h3>{props.name}</h3>
                {props.competition.map(i => <h4>{i}</h4>)}
                <button className="transition client-button" onClick={()=> showQuote()}>See what she says about Brittney</button>
            </div>
            <q className="testimonial">{props.testimonial}</q>
        </div>
    )
}