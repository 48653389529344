import React, { useState } from 'react'


export default function SingleQuestion(props) {
    const [ openState, setOpenState ] = useState({
        isOpen: false
    });
    function toggleOpen() {
        setOpenState( state => ({
            isOpen: !state.isOpen
        }))
    };
    return (
        <li onClick={() => toggleOpen() } className={openState.isOpen ? "expand" : ""}>
            <h3><span className="open"></span>{props.question}</h3>
            <div  className="accordion">
                <p>{props.answer}</p>
            </div>
        </li>
    )
}