import React, { Fragment, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import './style.css';
import Content from "../../content/home";

export default function About() {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({ defaults: {
        duration: 1
    }});
    const [ content ] = useState({
        ...Content
    });
    let { title, bio, bio2, images } = content[0].about[0];
    let smallerBio;
    let targetWidth = 10000;
    let w = document.documentElement.clientWidth ||
        document.body.clientWidth ||
        window.innerWidth;
        
    useEffect( ()=> {
        if( w >= targetWidth) {
            const tlName = gsap.timeline({
                duration:10,
                scrollTrigger: {
                    trigger: '#name-container',
                    start: 'top center',
                    end: 'top top',
                    pin: true,
                    pinSpacing: false,
                    toggleActions: 'play none none reverse',
                    scrub: true,
                    markers: true
                }
            });
            tlName.fromTo('.name-large', {
                x: '100%'
            }, {
                x: '-100%'
            });
            tl.from('#about', {
                autoAlpha: 0,
                scrollTrigger: {
                    trigger: '#about',
                    start: 'top center+=200',
                    end:'center center',
                    scrub: true,
                }
            });
        } else {
            console.log('about is too small')
        }
    })
    if( w > 750) {
        smallerBio = <img src={images[1].src} alt={images[1].alt} className="smallerBio" />
    }

    return (
        <Fragment>
            <div id="name-container">
                <p className="name-large">Brittney Eskew</p>
            </div>
            <section id="about" className="clearfix fade">
                <img src={images[0].src} alt={images[0].alt} className="largerBio" loading="lazy"/>
                <div className="bioText">
                    <h2>About <span>Brittney</span></h2>
                    <p>{bio}</p>
                    <p>{bio2}</p>
                </div>
            </section>
        </Fragment>
    )
}