import React, { useEffect, useState } from "react";
import Content from "../../content/home.json";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./style.css";
import Client from "../../components/Client";
export default function Clients(){
    gsap.registerPlugin(ScrollTrigger);
    
    let targetWidth = 10000;
    let w = document.documentElement.clientWidth ||
        document.body.clientWidth ||
        window.innerWidth;
    const [ content ] = useState({
        ...Content
    });
    // const [ clients, setClients ] = useState([]);
    const [ visible, setVisible ] = useState(4);

    const { title, client } = content[0].clients[0];

    let contestants = client.slice(0, visible).map( c =>
        <Client 
            id={c.id}
            img={c.img}
            name={c.name}
            competition={c.competition}
            testimonial={c.testimonial}
        />        
    );
    const showMoreItems = () => {
        setVisible(previous => previous + 2);
    }
    useEffect(() => {
        // setClients(contestants);
        if( w >= targetWidth) {
            const tlHeader = gsap.timeline({
                scrollTrigger: {
                    trigger: '.clients-header',
                    start: 'center center',
                    end: 'bottom top',
                    pin: true,
                    pinSpacing: false,
                    toggleActions: 'play none none reverse',
                    scrub: true
                }
            })
            const imgTween = gsap.utils.toArray('.client-image');
            const textTween = gsap.utils.toArray('.client');

            tlHeader.fromTo('.clients-header', { autoAlpha:0 },{ autoAlpha: 1, duration: .25 }).to('.clients-header', { autoAlpha: 0, duration: .25 }, .5);

            imgTween.forEach( box => {
                let tlImage = gsap.timeline({
                    scrollTrigger: {
                        trigger: box,
                        start: 'center center',
                        end: 'bottom top',
                        pin: true,
                        pinSpacing: false,
                        toggleActions: 'play none none reverse',
                        scrub: true
                    }
                })
                tlImage.from(box, {
                    autoAlpha: 0,
                    duration:.25
                }).to(box, {
                    autoAlpha: 0,
                    duration: .25
                },.75);
            });
            textTween.forEach(box => {
                let tlText = gsap.timeline({
                    scrollTrigger: {
                        trigger: box,
                        start: 'center center',
                        // end: 'center top',
                        end: '+=700',
                        pin: true,
                        toggleActions: 'play none none reverse',
                        scrub: true
                    }
                });
                tlText.from(box, {
                    duration: .4,
                    autoAlpha: 0,
                    y: 100  
                }).to(box, {
                    duration: .2,
                    autoAlpha: 0,
                    y: -100
                }, .8);
            });
        } else {
            console.log('client is too small')
        }
    }, []);
    return (
        <section id="clients" className="clients-grp clearfix fade">
            <h2 className="clients-header">{title}</h2>
            {contestants}
            <div className="see-more">
                <button onClick={showMoreItems}><span>See More</span></button>
            </div>
        </section>
    )
}