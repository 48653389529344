import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home" ;
import Terms from "./pages/Terms";

import './App.css';

export default function App() {
  // let app = useRef(null);
  // useEffect( () => {
  //   TweenLite.to(app, 0, {css: {visibility: 'visible'}})
  // }, [])
  // history.scrollRestoration = "manual";
  window.onbeforeunload = function () {
    window.scrollTo(0,0);
  };

  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Header />
        <Switch >
          <Route exact path="/terms" component={Terms} />
          <Route path="/" component={Home} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}