import React, { Fragment, useEffect, useState } from "react";
import {gsap, Power3} from 'gsap';
import Content from "../../content/home";
import "./style.css";

export default function Intro() {
    const [content] = useState({
        ...Content
    });
    const {title1, title2, copy} = content[0].introduction[0];
    let targetWidth = 850;
    let w = document.documentElement.clientWidth ||
        document.body.clientWidth ||
        window.innerWidth;

    useEffect( ()=>{
        if(w >= targetWidth ) {
            const tl = gsap.timeline({defaults: {
                ease: Power3.easeOut
            }});
            tl.from('#intro', {
                delay:2,
                duration:2,
                y:'100vh'
            }).to('.hero-pre', {
                display: 'none', 
                duration:.1, 
                delay:'-1'
            }).to('#intro',{
                backgroundColor: 'rgba(195,220,217,0)',
                duration:.25
            });
        } else {
            console.log('intro too small')
        }
    },[])
    return (
        <Fragment>
            <img src="img/asyaBranch3.jpg" className="hero-pre" alt="Eskew Consulting client Aysa Branch winning Miss USA in 2020" />
            <section id="intro">
                <div class="introCopy">
                    <h2>
                        <span className="headline1">{title1}</span> <span className="headline2">{title2}</span>
                    </h2>
                    <h1>We are pageant coaches that will help you with your interview, talent, stage presentation and resume development. Find your coach today.</h1>
                    {/* <p>{copy}</p> */}
                </div>
                <figure className="hero1">
                    <img 
                        alt="Welcome to Eskew Consulting" 
                        src="img/eskew-consulting-team1900.jpg" 
                        srcset="img/eskew-consulting-team320.jpg 320w,
                        img/eskew-consulting-team768.jpg 768w,
                        img/eskew-consulting-team1200.jpg 1200w,
                        img/eskew-consulting-team1900.jpg 1900w,
                        img/eskew-consulting-team3840.jpg 3000w"
                        sizes="(max-width: 320px) 280px,
                        (max-width: 768px) 720px,
                        (max-width: 1200px) 1140px,
                        (max-width: 1900px) 1840px,
                        3840px"
                    />
                    {/* <div className="overlay"></div> */}
                    {/* <figcaption className="congratulations">We would like to congratulate Eskew Consulting client, Aysa Branch on winning Miss USA 2020!</figcaption> */}
                </figure>
            </section>
        </Fragment>
    )
}