import React, { useEffect, useState } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Content from "../../content/home";
import "./style.css";

export default function Services() {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({ defaults: {
        duration: 1,
        ease: 'none'
    }});
    const [content] = useState({
        ...Content
    });
    // const [ title, services, images ] = content[0].services[0];
    const { title, subHeader, services, images, copy, video } = content[0].services[0];
    let listItems = services.map( (number)=> 
        <li>{number}</li>
    );
    let targetWidth = 10000;
    let w = document.documentElement.clientWidth ||
        document.body.clientWidth ||
        window.innerWidth;

    useEffect( ()=> {
        if(w >= targetWidth) {
            // tl.from('.first-image', {
            //     autoAlpha: 0,
            //     scrollTrigger: {
            //         trigger: '.first-figure',
            //         start: 'top center',
            //         end: 'bottom center',
            //         scrub: true,
            //         toggleActions: 'play none none reverse'
            //     }
            // }).from('.first-caption', {
            //     autoAlpha: 0,
            //     x:-100,
            //     scrollTrigger: {
            //         trigger: '.first-figure',
            //         start: 'top center+=100',
            //         end: 'bottom center',
            //         scrub: true,
            //         toggleActions: 'play none none reverse'
            //     }
            // });
            tl.from('.second-image', {
                autoAlpha: 0,
                x:-300,
                scrollTrigger: {
                    trigger: '.second-figure',
                    start: 'top bottom',
                    end: 'center center',
                    scrub: true,
                    toggleActions: 'play none none reverse'
                }
            }).from('.second-caption', {
                autoAlpha:0,
                y: 100,
                scrollTrigger: {
                    trigger: '.second-figure',
                    start: 'top center+=100',
                    end: 'bottom center',
                    scrub: true,
                    toggleActions: 'play none none reverse'
                }
            })
        } else {
            console.log('services too small')
        }
    })
    return (
        <section id="services" className="clearfix">
            {/* <figure className="first-figure">
                <img src={images[0].src} alt={images[0].alt} className="first-image"/>
                <figcaption className="first-caption">{images[0].alt}</figcaption>
            </figure> */}
            <h2 className="servicesHeader">{title}</h2>
            {/* <figure className="second-figure">
                <img src={images[1].src} alt={images[1].alt} className="second-image"/>
                <figcaption className="second-caption">{images[1].alt}</figcaption>
            </figure> */}
            <video autoPlay loop muted className="second-figure" poster="img/video-poster.jpg">
                <source src={video[0].src} type="video/mp4" />
            </video>
            <p className="servicesCopy">{copy}</p>
            <div className="servicesList">
                <h3>{subHeader}</h3>
                <ol>{listItems}</ol>
            </div>
        </section>
    )
}