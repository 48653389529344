import React, { useState } from "react";
import Content from "../../content/terms.json";
import "./style.css";

export default function Terms() {
    const [ content ] = useState({
        ...Content
    });
    const { title, titleText, bodyCopy } = content[0];


    let terms = bodyCopy.map( (t, index) => {
        return (
            <div class="term">
                <h3>{index + 1}. {t.title}</h3>
                <p>{t.text}</p>
            </div>
        )
    });
    console.log(content);
    console.log(`copy: ${bodyCopy}`)

    return (
        <section id="terms-page">
            <div className="terms-header">
                <h2>{title}</h2>
                <p>{titleText}</p>
            </div>
            {terms}
        </section>
    )
}